import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

const Categories = ({ t, chooseCategory }) => {
  const [categories] = useState([
    { key: 'a' },
    { key: 'w' },
    { key: 'm' },
    { key: 'u' },
    { key: 'd' },
  ])

  const [selectedCategory, setSelectedCategory] = useState(
    categories.length > 0 ? categories[0].key : null
  )

  const handleClick = key => {
    chooseCategory(key)
    setSelectedCategory(key)
  }

  return (
    <div>
      <span id="catalog"></span>
      <div className="categories">
        {categories.map(el => (
          <div
            key={el.key}
            onClick={() => handleClick(el.key)}
            style={{
              backgroundColor:
                el.key === selectedCategory ? '#e2e2e2' : '#f2f2f2',
            }}
          >
            {t(el.key)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default withTranslation()(Categories)
