import React, { useState, useEffect, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'

import { FaChevronLeft, FaChevronRight, FaCircleXmark } from 'react-icons/fa6'
import { useSwipeable } from 'react-swipeable'
import { motion } from 'framer-motion'

const ShowFullItem = ({
  i18n,
  t,
  item: initialItem,
  items,
  onShowItem,
  onAdd,
}) => {
  const gender = {
    m: '♂',
    w: '♀',
    u: '♂♀',
    d: ' ',
  }
  // Определение знака пунктуации в зависимости от текущей локали
  const dot = i18n.language === 'cn' ? '。' : '.'

  const [item, setItem] = useState(initialItem)
  const [changeItem, setChangeItem] = useState(false)
  const [direction, setDirection] = useState(1)

  const nextItemSwipe = useCallback(() => {
    setChangeItem(true)
    setDirection(1)
    const currentIndex = items.findIndex(i => i.id === item.id)
    const nextIndex = (currentIndex + 1) % items.length
    setItem(items[nextIndex])
  }, [item, items])

  const nextItem = event => {
    event.stopPropagation()
    nextItemSwipe()
  }

  const prevItemSwipe = useCallback(() => {
    setChangeItem(true)
    setDirection(-1)
    const currentIndex = items.findIndex(i => i.id === item.id)
    const prevIndex = (currentIndex - 1 + items.length) % items.length
    setItem(items[prevIndex])
  }, [item, items])

  const prevItem = event => {
    event.stopPropagation()
    prevItemSwipe()
  }

  const closeFullItem = useCallback(
    item => {
      setChangeItem(false)
      onShowItem(item)
    },
    [onShowItem]
  )

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'ArrowRight') {
        nextItemSwipe(event)
      } else if (event.key === 'ArrowLeft') {
        prevItemSwipe(event)
      } else if (event.key === 'Escape') {
        closeFullItem(item)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [item, nextItemSwipe, prevItemSwipe, closeFullItem])

  const handlers = useSwipeable({
    onSwipedLeft: nextItemSwipe,
    onSwipedRight: prevItemSwipe,
  })

  return (
    <div className="full-item" onClick={() => closeFullItem(item)}>
      <motion.div
        layoutId={changeItem ? `full-card-${item.id}` : `card-${item.id}`}
        initial={{ x: changeItem ? 1000 * direction : 0, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: changeItem ? -1000 * direction : 0, opacity: 0 }}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 25,
          duration: 0.25,
        }}
        key={item.id}
        {...handlers}
      >
        <img
          src={'./img/' + item.id + '.jpg'}
          alt={t('n_' + item.id)}
          onClick={() => closeFullItem(item)}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <FaChevronLeft
            className="fa-button"
            style={{ left: 40, top: 400 }}
            onClick={event => prevItem(event)}
          />
          <FaChevronRight
            className="fa-button"
            style={{ right: 40, top: 400 }}
            onClick={event => nextItem(event)}
          />
          <FaCircleXmark
            className="close-button"
            onClick={() => closeFullItem(item)}
          />
        </motion.div>
        <h2>
          {t('n_' + item.id)}
          <span className="gender">{gender[t('c_' + item.id)]}</span>
        </h2>
        <p>
          {t('g_' + item.id).length > 2 && (
            <>
              <em>{t('aroma_group')}:</em> {t('g_' + item.id)}
              <br />
            </>
          )}
        </p>
        <p>
          {t('h_' + item.id).length > 2 && (
            <>
              <em>{t('high_notes')}:</em> {t('h_' + item.id)}
              <br />
            </>
          )}
          {t('m_' + item.id).length > 2 && (
            <>
              <em>{t('heart_notes')}:</em> {t('m_' + item.id)}
              <br />
            </>
          )}
          {t('l_' + item.id).length > 2 && (
            <>
              <em>{t('base_notes')}:</em> {t('l_' + item.id)}
              <br />
            </>
          )}
        </p>
        {t('d_' + item.id).length > 2 && (
          <p>
            <em>{t('desc')}:</em>{' '}
            {<Trans i18nKey={`d_${item.id}`} components={{ 1: <br /> }} />}
            {dot}
          </p>
        )}
        <b>
          {new Intl.NumberFormat().format(t('p_' + item.id))} {t('rub')}
        </b>
        <div className="add-to-cart-full" onClick={() => onAdd(item)}>
          {t('to_cart')}
        </div>
      </motion.div>
    </div>
  )
}

export default withTranslation()(ShowFullItem)
