import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { FaPlus } from 'react-icons/fa6'

const showPriceOld = t => {
  return (
    <>
      &nbsp;&nbsp;
      <span className="price-old">
        &nbsp;
        {new Intl.NumberFormat().format(t('price_old'))}&nbsp;{t('rub')}
        &nbsp;
      </span>
    </>
  )
}

export function Item(props) {
  const [currentItem, setCurrentItem] = useState(null)
  const { t } = useTranslation()
  const gender = {
    m: '♂',
    w: '♀',
    u: '♂♀',
    d: ' ',
  }

  const handleClick = () => {
    setCurrentItem(props.item.id)
  }

  return (
    <motion.div
      className="item"
      layoutId={`card-${props.item.id}`}
      onClick={handleClick}
      style={{
        zIndex: currentItem === props.item.id ? 20 : 10,
      }}
    >
      <img
        src={'./img/' + props.item.id + '.jpg'}
        alt={t('n_' + props.item.id)}
        onClick={() => props.onShowItem(props.item)}
      />
      <div className="item-info">
        <h2 onClick={() => props.onShowItem(props.item)}>
          {t('n_' + props.item.id)}&nbsp;
          <span className="gender">{gender[t('c_' + props.item.id)]}</span>
        </h2>

        <p onClick={() => props.onShowItem(props.item)}>
          {t('g_' + props.item.id).length > 2 && (
            <>
              <em>{t('aroma_group')}:</em> {t('g_' + props.item.id)}
              <br />
            </>
          )}
        </p>
        <p onClick={() => props.onShowItem(props.item)}>
          {t('g_' + props.item.id).length > 2 && (
            <>
              <em>{t('high_notes')}:</em> {t('h_' + props.item.id)}
              <br />
            </>
          )}
          {t('g_' + props.item.id).length > 2 && (
            <>
              <em>{t('heart_notes')}:</em> {t('m_' + props.item.id)}
              <br />
            </>
          )}
          {t('l_' + props.item.id).length > 2 && (
            <>
              <em>{t('base_notes')}:</em> {t('l_' + props.item.id)}
              <br />
            </>
          )}
          <span className="more" onClick={() => props.onShowItem(props.item)}>
            {t('more')}...
          </span>
          <br />
          <br />
          <br />
        </p>
        <div>
          {/* Выводим цену, если старая цена выше нуля — выводим и её */}
          <b onClick={() => props.onShowItem(props.item)}>
            {new Intl.NumberFormat().format(t('p_' + props.item.id))}
            &nbsp;{t('rub')}
            {t('price_old') > 0 ? showPriceOld(t) : ''}
          </b>
        </div>
        <div className="add-to-cart" onClick={() => props.onAdd(props.item)}>
          <FaPlus />
        </div>
      </div>
    </motion.div>
  )
}

export default Item
